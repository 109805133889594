import styled from "styled-components/macro";
import { Icon } from "assets/Icons_";
import {
  active_project,
  current_language,
  currentActiveProject,
  iso_free,
  iso_isrotating,
} from "state/store.global";
import { getFocus, useAtom, useGet } from "state/jotai";
import { useBreak } from "hooks/useBreak";
import { BREAK_ } from "constants";
import { _project } from "state/store.projects";
import { useNavigate } from "react-router-dom";

export const OverviewBack = ({}) => {
  const navigateTo = useNavigate();
  const currentLanguage = useAtom(current_language)[0];
  const setActiveProject = useAtom(currentActiveProject)[1];
  const { pin, pid } = useGet(active_project);
  const buttonsLabel = getFocus(_project, "buttonsLabel")?.[pin]?.[pid] ?? [];

  const isMobile = useBreak("md_dn");
  const click = () => {
    setActiveProject("");
    navigateTo(`/${currentLanguage}/isometry`);
  };

  return (
    <Box onClick={click}>
      <Icon icon="back" size={isMobile ? "18" : "18"} color="#000" />
      <Text>
        {
          buttonsLabel?.filter(
            (btn) => btn.UILabelProperty === "TilbageOversigt"
          )[0]?.Label
        }
      </Text>
    </Box>
  );
};

const Text = styled.div`
  white-space: nowrap;
  font-size: 18px;
  font-weight: 400px;
  /* border: 1px solid black; */
`;
const Box = styled.div`
  gap: 5px;
  cursor: pointer;
  padding: 5px 15px;
  z-index: 5;
  align-items: center;
  display: flex;
  gap: 5px;
  user-select: none;
  position: absolute;
  top: 70px;
  left: 165px;
  ${BREAK_.md_up} {
    position: absolute;
    // top: 105px;
    // left: 40px;
    top: 75px;
    left: 30px;
    padding: 0;
  }
`;
