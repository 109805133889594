import { Box } from "@mui/material";
import PropTypes from "prop-types";
import React from "react";
import { active_project, vis_calculate_benefit } from 'state/store.global'
import { getFocus, useAtom, useGet } from 'state/jotai'
import { useHousingBenefit } from 'components/HousingBenefit/HousingBenefit.hook'
import { _project } from "state/store.projects";

function Properties({ groups, indent }) {
  const setBenefit = useAtom(vis_calculate_benefit)[1]
  const output = useHousingBenefit()

  const { pin, pid } = useGet(active_project)
  const buttonsLabel = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []

  return (
    <>
      {groups &&
        groups.map((group, i) => {
          return (
            <Box
              key={i}
              sx={{
                mb: 5,
                "&:last-child": {
                  mb: "0",
                },
              }}
            >
              <Box sx={{ fontWeight: 700, mb: "10px" }}>{group.title}</Box>
              <div>
                {group.data &&
                  group.data
                    .filter(data => data.Value !== null && data.Value !== "") // Filter out null or empty string values
                    .map((data, j) => {
                      const label = data.Label;
                      // console.log(data?.Value, "label")
                      const value =
                        data.Value === true
                          ? buttonsLabel?.filter(btn => btn?.UILabelProperty === 'Ja')[0]?.Label
                          : data.Value === false
                          ? buttonsLabel?.filter(btn => btn?.UILabelProperty === 'Nej')[0]?.Label
                          : data.Value;
                      const postfix = data.Postfix ?? "";

                      return (
                        <Box
                          key={j}
                          sx={{
                            display: 'flex',
                            justifyContent: 'space-between',
                            px: indent ? 3 : 0,
                            py: 1.5,
                            borderBottom: '1px solid rgba(112, 112, 112, 0.1)',
                            '&:last-child': {
                              borderBottom: 'none',
                            },
                          }}
                        >
                          <div>{label}</div>
                          {data.PropertyID !== 113 ? (
                            <div>
                              {value}
                              {postfix}
                            </div>
                          ) : (
                            <>
                              {
                                output
                                  ? <div onClick={() => setBenefit(true)} style={{ color: 'unset', cursor: 'pointer', textDecoration: 'underline' }}>{output}</div>
                                  : <div onClick={() => setBenefit(true)} style={{ color: 'unset', cursor: 'pointer', textDecoration: 'underline' }}>{buttonsLabel?.filter(btn => btn.UILabelProperty === 'Beregn')[0]?.Label}</div>
                              }
                            </>
                          )}
                        </Box>
                      )
                    })}
              </div>
            </Box>
          );
        })}
    </>
  );
}

Properties.propTypes = {
  groups: PropTypes.array.isRequired,
  indent: PropTypes.bool,
};

export default Properties;
