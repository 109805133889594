import { css } from 'styled-components'
import { BREAK_ } from 'constants'

export const root = css`
  /* Create a root stacking context */

  #root {
    ${'' /* display: flex; */}
    ${'' /* flex-direction: row; */}
    ${'' /* ${ BREAK_.md_dn } { flex-direction: column }; */}
    ${'' /* isolation: isolate; */}
    ${'' /* width:100%; */}
    height:100%;
    ${'' /* border:10px solid blue; */}
  }
  /* For WebKit-based browsers */
  ::-webkit-scrollbar {
    width: 5px;
    height: 5px;
  }
  
  ::-webkit-scrollbar-track {
    background-color: transparent;
  }
  
  ::-webkit-scrollbar-thumb {
    background-color: #909090;
    border-radius: 20px;
  }
  
  ::-webkit-scrollbar-thumb:hover {
    background: #aaa;
  }
  
  /* For Firefox */
  scrollbar-color: lightgray white;
  scrollbar-width: thin;

  ::-webkit-scrollbar-track {
    background-clip: padding-box;
    background: #dddddd;
  }
  /* Chrome, Safari, Edge, Opera */
  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  /* Firefox */
  input[type="number"] {
    -moz-appearance: textfield;
  }
`