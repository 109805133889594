import {
  Box,
  Button,
  Checkbox,
  CircularProgress,
  FormControl,
  FormControlLabel,
  Grid,
} from "@mui/material";
import Unit from "components/Unit/Unit.model";
import PropTypes from "prop-types";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { CustomInput, CustomLabel } from "./styled-components";
import SuccessMessage from "./SuccessMessage";
import useHubspot from "./useHubspot";
import { getFocus, useGet } from "state/jotai";
import { active_project } from "state/store.global";
import { _project } from "state/store.projects";
import { useBreak } from "hooks/useBreak";

const ContactForm = ({ grid, unit, children }) => {
  const { submit, successMsg, errorMsg, inProgress, submitted } = useHubspot();
  const { register, handleSubmit, reset, formState: { errors, isValid }, } = useForm();

  const { pin, pid } = useGet(active_project) 
  const buttonsLabel  = getFocus(_project, 'buttonsLabel')?.[pin]?.[pid] ?? []
const isDesktop = useBreak('form_md_down')
  const unitNo = unit?.getValue("Projeknr") ? `${buttonsLabel?.filter( btn => btn?.UILabelProperty === 'Bolignr')[0]?.Label} ${unit.getValue("Projeknr")},` : "";
  const unitAddress = unit?.getAddress() ?? "";

  const { i18n: { language }, } = useTranslation();
  const homeUrl = window.location.origin;
  const unitUrl = unit?.getUrl(`${homeUrl}/${language}`) ?? "";

  const [checked, setChecked] = React.useState(false);

  const handleCheckbox = (event) => { setChecked(event.target.checked); };

  useEffect(() => {
    if (submitted) {
      reset();
      setChecked(false);
    }
  }, [submitted]);

  const onSubmit = (data) => {
    const dataFields = [];
    for (let key in data) {
      if (key !== "_valid") {
        dataFields.push({ name: key, value: data[key] });
      }
    }

    submit(dataFields);
  };

  return (
    <>
      {submitted ? (
        <SuccessMessage />
      ) : (
        <>
          <Box
            sx={{
              position: 'relative',
               color   : isDesktop ? clr.sub_text_color: clr.alternative_condition
            }}
          >
            {children}
            {/* progress */}
            {inProgress && (
              <Box
                sx={{
                  position: 'absolute',
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  left: 0,
                  top: 0,
                  width: '100%',
                  height: '100%',
                  zIndex: 2,
                }}
              >
                <Box
                  sx={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    width: 100,
                    height: 100,
                    backgroundColor: 'rgba(0,0,0,0.25)',
                  }}
                >
                  <CircularProgress />
                </Box>
              </Box>
            )}

            <form onSubmit={handleSubmit(onSubmit)}>
              {/* <input
          type="hidden"
          {...register("address")}
          value={`${unitNo} ${unitAddress}`}
        /> */}
              <input
                type='hidden'
                {...register('bolignummer')}
                value={`${unitNo} ${unitAddress}`}
              />
              {/* <input type="hidden" {...register("website")} value={unitUrl} /> */}
              <input
                type='hidden'
                {...register('source__estate_tool_')}
                value={unitUrl}
              />

              <Grid
                container
                columnSpacing={3}
                rowSpacing={grid ? 2 : 1}
                sx={{ mb: 3 }}
              >
                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.firstname ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'Formularfornavn')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput
                      {...register('firstname', { required: true })}
                    />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEfternavn')[0]?.Label }</CustomLabel>
                    <CustomInput {...register('lastname')} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.email ? true : false}
                  >
                    <CustomLabel shrink>
                      { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularEmail')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput {...register('email', { required: true })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl
                    sx={{ mb: 3 }}
                    fullWidth
                    variant='filled'
                    error={errors.phone ? true : false}
                  >
                    <CustomLabel shrink>
                     { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularTelefon')[0]?.Label } &nbsp;<span>*</span>
                    </CustomLabel>
                    <CustomInput {...register('phone', { required: true })} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularAdresse')[0]?.Label }</CustomLabel>
                    <CustomInput {...register('address')} />
                  </FormControl>
                </Grid>

                <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularPostnummer')[0]?.Label }</CustomLabel>
                    <CustomInput {...register('zip')} />
                  </FormControl>
                </Grid>

                {/* <Grid item md={grid ? 6 : 12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'PersonalIdentityNumber')[0]?.Label }</CustomLabel>
                    <CustomInput type="number" {...register('personal_identity_number')} />
                  </FormControl>
                </Grid> */}

                <Grid item md={12} xs={12}>
                  <FormControl sx={{ mb: 3 }} fullWidth variant='filled'>
                    <CustomLabel shrink>{ buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularKommentar')[0]?.Label }</CustomLabel>
                    {/* <CustomInput multiline rows={4} {...register("message")} /> */}
                    <CustomInput
                      multiline
                      rows={4}
                      {...register('kommentar')}
                    />
                  </FormControl>
                </Grid>
              </Grid>

              <Box sx={{ mb: 3 }}>
                <CustomLabel>
                  { buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelser')[0]?.Label } &nbsp;<span>*</span>
                </CustomLabel>
                <FormControlLabel
                  sx={{
                    mr: 0,
                  }}
                  control={
                    <Checkbox
                      name='_value'
                      checked={checked}
                      onChange={handleCheckbox}
                    />
                  }
                  label={
                    <Box>
                      <div dangerouslySetInnerHTML={{ __html: buttonsLabel?.filter( btn => btn.UILabelProperty === 'FormularBetingelserTekst')[0]?.Label }}>
                      </div>
                      {/* Jeg accepterer{' '}
                      <strong>
                        <a 
                          style={{color:clr?.primary_text}}
                          target='_blank'
                          href='https://unity-living.com/da/about-unity/betingelser/'>
                          betingelserne
                        </a>
                      </strong>{' '}
                      for tilmelding */}
                    </Box>
                  }
                />
              </Box>

              <Box
                sx={{
                  textAlign: grid ? 'left' : 'center',
                }}
              >
                <Box>
                  <Button
                    variant='contained'
                    size='large'
                    type='submit'
                    disabled={!checked || !isValid}
                  >
                     { buttonsLabel?.filter( btn => btn.UILabelProperty === 'sendButton')[0]?.Label }
                  </Button>
                </Box>
                <Box
                  sx={{
                    my: 3,
                    color: 'success.main',
                  }}
                >
                  <Box sx={{ color: 'success.main' }}>{successMsg}</Box>
                  <Box sx={{ color: 'error.main' }}>{errorMsg}</Box>
                </Box>
              </Box>
              {/* <div style={{height:50}}/> */}
            </form>
          </Box>
        </>
      )}
    </>
  )
};

ContactForm.propTypes = {
  children: PropTypes.node,
  grid: PropTypes.bool,
  unit: PropTypes.instanceOf(Unit),
};

export default ContactForm;
